<template>
  <v-card class="p-rel">
    <v-btn x-small fab dark color="primary" class="close-modal" @click="$emit('close')">
      <v-icon small color="white">$close</v-icon>
    </v-btn>
    <v-row class="ma-0">
      <modals-sidebar title="Quitter l'espace" />
      <v-col cols="11" class="pt-2">
        <form-category title="Mon espace" subtitle="Je souhaite quitter cet espace"
                       class="mt-4"
        />
        <h3 class="subtitle-2 font-weight-bold primary--text">Pour quitter cet espace :</h3>
        <div class="text-caption mb-1">
          - Si vous êtes propriétaire de l'espace, veuillez transferer la propriété de l'espace à un autre membre.
        </div>
        <div class="text-caption mb-1">
          - Si vous êtes propriétaire de groupes, veuillez transferer la propriété de vos groupes à un autre membre.
        </div>


        <v-card-actions>
          <v-spacer />
          <v-btn x-small class="mx-1" @click="$emit('close')">Annuler</v-btn>
          <v-btn color="secondary" x-small class="mx-1" :loading="leave" :disabled="leave" @click="leaveSpace">
            Quitter l'espace
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import ModalsSidebar from '@/modules/core/modals/ModalsSidebar'
  import FormCategory from '@/modules/core/layout/FormCategory'
  import {Space} from '@/models'
  import * as Spaces from '@/modules/spaces/api/index'

  export default {
    name: 'SpaceLeaveModal',
    components: {FormCategory, ModalsSidebar},
    data() {
      return {
        leave: false
      }
    },
    computed: {
      space() {
        return Space.get(this.$store.state.core.space)
      }
    },
    methods: {
      async leaveSpace() {
        try {
          this.leave = true
          await Spaces.leave(this.$store.state.core.space)
          this.$store.commit('layout/toggleSpaceSelector')
        } catch (e) {
          await this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue',
            subtitle: 'Veuillez transferer vos groupes ou l\'espace dont vous êtes propriétaire.'
          })
          this.$emit('close')
        } finally {
          this.leave = false
          this.$emit('close')
        }
      }
    }
  }
</script>
